import SnackAdUnit from "../components/SnackAds/SnackAdUnit";

function About(params) {
    return <div>
        <h1>Aut dolorem reprehenderit aut quam dolor nam provident necessitatibus. </h1>
        <p>Lorem ipsum dolor sit amet. Ab doloribus fugiat hic sint doloremque <em>Sed enim et repellat eaque est facilis voluptas</em>. Ut corrupti incidunt <strong>Et iusto</strong> 33 nostrum ratione. Est assumenda architecto id iusto doloremque non corporis deleniti. </p>
        <h2>Hic illo dignissimos sed impedit internos et ipsum nihil. </h2>
        <p>Et accusamus galisum ab doloremque cupiditate qui similique possimus et iste laborum ab voluptatum veritatis non fugiat deserunt non voluptate perferendis! Est voluptatibus libero <em>Quo optio ut dolor consequuntur At magnam magni</em> aut iste necessitatibus est laboriosam nemo? Aut nostrum fugit <a href="https://www.loremipzum.com" target="_blank">Aut unde</a> non quasi dolore et eaque deserunt? </p>
        <dl>
            <dt>
                <dfn>Eos aliquid porro. </dfn>
            </dt>
            <dd>Eos debitis vero ut suscipit iste. </dd>
            <dt>
                <dfn>Aut maxime reprehenderit eos galisum assumenda. </dfn>
            </dt>
            <dd>Sit fugiat similique non molestias sunt et ducimus dignissimos? </dd>
            <dt>
                <dfn>Non quas autem et animi reiciendis? </dfn>
            </dt>
            <dd>Ut cumque accusantium aut officiis error. </dd>
        </dl>
        <h3>Rem dolores tenetur et sapiente reiciendis ut architecto iste. </h3>
        <p>Ut illum temporibuseos quod et blanditiis adipisci! Aut iusto ipsam <em>Ut illo</em> aut voluptas quisquam. At dignissimos dolorem <strong>Ab commodi</strong> qui possimus error. Ab modi perspiciatis est quidem eligendi eum voluptate quidem qui voluptate pariatur qui magnam magnam est recusandae voluptates. </p>
        <ul>
            <li>Hic quod rerum quo amet totam ad similique assumenda non galisum quia. </li>
            <li>Sit officiis beatae ut velit sapiente et saepe maxime. </li>
            <li>Quo molestias nesciunt est laudantium voluptatem 33 possimus itaque id impedit voluptates! </li>
            <li>Ut reiciendis Quis non alias perspiciatis. </li>
            <li>Hic deserunt animi aut eaque saepe. </li>
            <li>Est quis sint eos asperiores sunt sit voluptatibus recusandae. </li>
        </ul>

        <SnackAdUnit unitName="snack_dex3" siteId='13'></SnackAdUnit>

        <h4>Id obcaecati tempore quo laudantium similique ut autem quod. </h4>
        <p>Qui cumque alias <a href="https://www.loremipzum.com" target="_blank">Et iure</a> ab quia pariatur ab laudantium assumenda. Vel impedit natus <em>Impedit qui dicta perspiciatis et aperiam aliquid</em> aut expedita minima ut quibusdam ratione. Et neque dolores et libero nihil nam nesciunt consequatur. Aut obcaecati libero vel magni facilis aut velit unde ex libero beatae. </p>
        <h5>Ut omnis eius et voluptatem perferendis ad odit odit. </h5>
        <p>Ut quia sunt et eligendi eligendi <em>Est quod eum minus provident</em> et porro quia quo aliquid dolorem est debitis quae. Aut aspernatur assumenda id nobis ullam et dolorum molestias sit quidem sequi. Et molestias nostrum eum doloremque ipsum eum voluptatem fuga ex illum perspiciatis aut exercitationem laboriosam in fugit molestias. Aut veritatis tempore quo sunt nisi sed voluptate accusamus id Quis reprehenderit qui ratione dignissimos. </p>
        <blockquote cite="https://www.loremipzum.com">A saepe aliquam sed quibusdam quod sed molestiae aspernatur qui quia internos? </blockquote>
    </div>
}

export default About;