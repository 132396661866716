import SnackAdUnit from "../components/SnackAds/SnackAdUnit";

function Home(params) {
    return <div>
        <h1>Eligendi vitae et voluptas suscipit qui mollitia dolor. </h1>
        <p>Lorem ipsum dolor sit amet. Et aspernatur omnis aut dolor nulla <em>Et rerum ea corporis corporis</em>? Et dolorum optio <strong>Est incidunt ut quia dolorum est consequatur minus</strong> ut vitae doloremque 33 aliquam odit. Qui dolorem incidunt aut nostrum ullam ut facilis numquam! </p>
        <h2>At temporibus assumenda qui minima quaerat. </h2>
        
        <SnackAdUnit unitName="snack_ldb" siteId='13'></SnackAdUnit>

        <p>Est doloremque neque qui commodi dolorem est dolorem necessitatibus est quia rerum id unde vero? Qui dicta unde <strong>Ut culpa sed architecto voluptate non voluptatem quam eos velit quidem</strong>. A nemo quam et praesentium omnis <em>Non iusto aut dicta perferendis eum minima iste</em>. Non expedita fugiat qui voluptatem molestiae in internos autem 33 illo atque qui velit animi nam velit voluptas eos magni voluptatem. </p>
        <ul>
            <li>Aut aperiam dignissimos et autem nihil est consequatur veritatis. </li>
            <li>Ut quia amet 33 alias officia rem placeat veniam et ipsam rerum. </li>
            <li>Et nemo quasi aut assumenda excepturi aut galisum laborum! </li>
            <li>Sit reprehenderit assumenda qui aspernatur porro. </li>
            <li>Est libero quidem est dolore exercitationem et illum architecto qui quia quae. </li>
        </ul>
        <blockquote cite="https://www.loremipzum.com">Sit nostrum laborum dolores distinctio et natus esse est consequatur illum in quos magni et reprehenderit omnis. </blockquote>
        <h3>Ut voluptatum iusto a fuga facere. </h3>
        <p>Qui voluptatibus doloremque <em>Et dolorum ad vitae dolore cum molestiae voluptas</em> sit voluptas consequatur quo totam velit! Id itaque soluta eum amet dolore et ducimus distinctio est eaque omnis est similique aliquid et deleniti adipisci. Ea reprehenderit dolores <strong>Sed quidem aut amet facilis sit dolor repellendus sed alias omnis</strong> in quidem nesciunt et fuga impedit. </p>
        <h4>Sed pariatur veritatis ut animi itaque. </h4>

        <SnackAdUnit unitName="snack_dex9" siteId='13'></SnackAdUnit>

        <p>Vel deleniti voluptatem sed praesentium temporibuset quibusdam. 33 perferendis nihil sed dolor alias sed sunt repudiandae et officiis accusantium! </p>
        <dl>
            <dt>
                <dfn>Sit corporis laborum? </dfn>
            </dt>
            <dd>Id esse eveniet est quam internos ea ducimus nesciunt qui doloremque quia. </dd>
            <dt>
                <dfn>Ut perferendis illo. </dfn>
            </dt>
            <dd>Non tempora numquam non accusamus atque. </dd>
            <dt>
                <dfn>Id aspernatur autem. </dfn>
            </dt>
            <dd>Qui galisum natus ut nostrum iste est tempore galisum aut rerum voluptatem. </dd>
        </dl>
        <h5>Nam suscipit voluptate aut adipisci impedit. </h5>
        <p>Et voluptatum quaerat ex porro dolores <strong>Eum totam ut nisi molestiae ut esse numquam</strong>. Ullam laboriosam <em>Ad magni est modi porro</em>! In similique cupiditate sed iure vel iusto voluptates qui eius tempore et dolor repellat. Eum voluptatem dolores cum eaque similique ad quas earum. </p>
    </div>
}

export default Home;