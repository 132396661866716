import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navigation from './components/navigation/MainNavigation';
import Home from './pages/Home';
import About from './pages/About';

function App() {
  return (
    <div className='page-container'>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;