import './MainNavigation.css';
import { Link } from "react-router-dom";

function Navigation(params) {
    return (
        <div className="navigation-container">
            <div className='nav-row'>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                </ul>
                <div className='logo-container'>
                    <img src="/snack-labs.png" alt="" />
                </div>
            </div>
            <hr />
        </div>
    );
}

export default Navigation;